export const loadBlock = async (chunk, selector, container, options = {}) => {
    // be safe and surround everything in try-catch to not affect other components
    try {
        const domEntryPoints = (container || document.body).querySelectorAll(selector)

        // do nothing if we don't need to load anything
        if (!domEntryPoints.length) {
            return []
        }

        const blockModule = await chunk()

        return Array.from(domEntryPoints).map((element, index) => {
            return new blockModule.default(element, container, index, options)
        })
    }
    catch (error) {
        console.error(error)
        return []
    }
}
